@import '../../../../scss/theme-bootstrap';

.hero-full-width {
  @include container-max-width-x-large;
  &__content-wrapper {
    @include container-padding-rules;
    &--pc {
      display: none;
      visibility: hidden;
      @media #{$cr19-large-up} {
        display: block;
        visibility: visible;
      }
      .hero-full-width__content {
        display: flex;
        flex-direction: column;
        height: 100%;
        position: relative;
      }
    }
    &--mobile {
      margin-top: 20px;
      @media #{$cr19-large-up} {
        display: none;
      }
      // Overriding helper text styles from mantle - mobile always needs to be off-black when text drops below image.
      .text-color {
        color: $cr19-text;
        &--black,
        &--white,
        &--grey {
          color: $cr19-text;
          p {
            color: $cr19-text;
          }
        }
      }
      .hero-full-width__disclaimer {
        padding-top: 15px;
      }
    }
  }
  // Typography defaults when none are selected in cms
  .content-block__line {
    &--eyebrow {
      .style--bare {
        @include text-body-text--tags;
      }
    }
    &--headline {
      .style--bare {
        @include text-title--large;
      }
    }
    &--content {
      .style--bare {
        @include text-body-text;
      }
    }
  }
  .content-block__links {
    min-height: auto;
    padding-bottom: 0;
    a {
      &.elc-button.button--light {
        @include elc-button;
        @include elc-button--light;
        &.elc-button--disabled,
        &:disabled {
          border-color: $cr19-border-light;
          color: $cr19-text-grey;
          &:hover,
          &:active,
          &:visited {
            border-color: $cr19-border-light;
            color: $cr19-text-grey;
          }
        }
      }
      &.elc-button.button--dark {
        @include elc-button;
        @include elc-button--dark;
        &.elc-button--disabled,
        &:disabled {
          background-color: $cr19-border-light;
          border-color: $cr19-border-light;
          &:hover,
          &:active,
          &:visited {
            background-color: $cr19-border-light;
            border-color: $cr19-border-light;
            color: $cr19-text-white;
          }
        }
      }
      &.elc-button.button--inverted {
        @include elc-button;
        @include elc-button--inverted;
        &.elc-button--disabled,
        &:disabled {
          border-color: $cr19-border-light;
          color: $cr19-text-grey;
          &:hover,
          &:active,
          &:visited {
            background-color: $cr19-border-light;
            border-color: $cr19-border-light;
            color: $cr19-text-grey;
          }
        }
      }
    }
  }
  // To reduce CLS only on MPP nodes
  &__picture {
    .node-type-elc-mpp & {
      min-height: 150px;
      @media #{$cr19-large-up} {
        min-height: 300px;
      }
    }
  }
  &__links {
    &--stacked {
      @media #{$cr19-large-up} {
        flex-direction: column;
        > div:first-of-type {
          margin: 0 0 20px;
        }
      }
    }
  }
}
